

.cover-img{
    height: 50%;
    width: 100%;
    object-fit: cover;

}

.content{
    --background:#f3f2f2;
}
.container{
 
max-width: 350px;
background: #fff;
   
    border-radius: 1rem;
}

button{
    height: 50px;
}